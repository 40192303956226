<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form class="row" @submit="saveBanner" @reset="onReset">
              <div class="col-md-6">
                <b-form-group label="Banner(*):">
                  <div  >
                    <div style=" margin-bottom: 20px" v-show="form.banner.length >0">
                      <img
                          style="width: auto; height: 100px"
                          :src="form.banner"
                          alt=""
                      />
                    </div>
                    <b-form-file
                        @change="onImageChange($event,'banner')"
                        :required="form.banner.length > 0?false:true"
                        placeholder="chọn ảnh banner "
                        accept="image/*"
                        type="file"
                    ></b-form-file>
                  </div>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="Banner PC(*):">
                  <div  >
                    <div style=" margin-bottom: 20px" v-show="form.banner_pc.length >0">
                      <img
                          style="width: auto; height: 100px"
                          :src="form.banner_pc"
                          alt=""
                      />
                    </div>
                    <b-form-file
                        @change="onImageChange($event,'banner_pc')"
                        :required="form.banner_pc.length > 0?false:true"
                        placeholder="chọn ảnh banner PC "
                        accept="image/*"
                        type="file"
                    ></b-form-file>
                  </div>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <div class="form-inline ">
                  <input  id="show-bn-cms" class="form-control" type="checkbox" :true-value="1" :false-value="0" value="1" v-model="form.status">
                  <label for="show-bn-cms"  style=" margin-left: 10px">Hiển thị </label>
                </div>
              </div>



              <div class="col-md-12">
                <b-button type="submit" variant="primary">{{
                    is_edit ? "Cập nhập" : "Xác nhận"
                  }}</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "FormCategory",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      form: {
        banner: '',
        banner_pc: '',
        status: 1,

      },
      is_edit: false,
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Banner thể lệ " },
    ]);
  },
  methods: {
    onImageChange(e,type) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia(type);
    },
    uploadMedia: async function (type) {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              if (type==='banner_pc'){
                this.form.banner_pc = response.data.data.link;
              }else {
                this.form.banner = response.data.data.link;
              }

            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    async loadBanner() {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CampaignRepository.getBanner();
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let bannerInfo = JSON.parse(body.data.data.value);
          this.form.status = bannerInfo.status;
          //this.form.banner = bannerInfo.banner;
          if (bannerInfo.banner){
            this.form.banner = bannerInfo.banner;
          }
          if (bannerInfo.banner_pc){
            this.form.banner_pc = bannerInfo.banner_pc;
          }
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    saveBanner: async function () {

      this.form.extra_data = JSON.stringify(this.configs);
      let params = this.form;
      this.$bus.$emit("show-loading", true);
      CampaignRepository.storeBanner(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: 'Cập nhật banner thành công',
            });
          }
        })
        .catch((response) => {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        banner: '',
      }

    },
  },
  created() {
    this.loadBanner();
  },
};
</script>

<style scoped>
.title-block{
  color: #FF0000;
  font-size: 18px;
  font-weight: bold;
}
</style>
